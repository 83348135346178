import { createContext } from "react";

interface UrlContextData {
  url: string;
}

export const initialUrlContextData: UrlContextData = {
  url: "",
};

const UrlContext = createContext(initialUrlContextData);

export default UrlContext;
