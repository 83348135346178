enum Color {
  TOOLBAR = "#26272C",
  TOOLBAR_TEXT = "#FFFFFF",

  BACKGROUND = "#FFFFFF",

  DIVIDER = "#000",

  RESULT_BACKGROUND = "#26272C",
  TEXT_RESULT = "#FFFFFF",
  RESULT_ICON = "#6D6D6D",

  TEXT_CARD_TITLE = "#000",

  CHART_AXIS = "#FFF",



  PRIMARY_BRAND = "#0591CE",
  PRIMARY_HOVER = "#06AAF1",
  BACKGROUND_DARKEST_TRANSPARENT = "rgba(0,0,0,0.5)",
  BACKGROUND_DARKEST = "rgba(0,0,0,0.5)",
  BACKGROUND_DARKER = "#6D6D6D",
  BACKGROUND_DARK = "#E0E0E0",
  BACKGROUND_LIGTH = "#E3E3E3",
  BACKGROUND_LIGHTER = "#FAFAFA",
  BACKGROUND_LIGHTER_TRANSPARENT = "rgba(250, 250, 250, 0.3)",
  BACKGROUND_LIGHTER_LESS_TRANSPARENT = "rgba(250, 250, 250, 0.7)",
  BACKGROUND_LIGHTEST = "#FFFFFF",
  BACKGROUND_HOVER = "#FDFDFD",
  BORDER_LIGHT = "#E3E3E3",
  BORDER_BRAND = "#0591CE",
  TEXT_DARKER = "#58595B",
  TEXT_DARK = "#B9BAD4",
  TEXT_LIGHT = "#B9B9B9",
  TEXT_LIGHT_RGBA = "185,185,185",
  TEXT_LIGHTER = "#FAFAFA",
  TEXT_LIGHTEST = "#FFFFFF",
  TEXT_BRAND = "#0591CE",
  SIDEBAR_ICON= "#B9B9B9",
  ERROR = "#FD6A6A",
  BATTERY_EMPTY = "#FF5E5E",
  BATTERY_FULL = "#76FF8C",
  BATTERY_PARTIAL = "#A2E1FD",
  SHADOW_DARKEST_TRANSPARENT = "rgba(0, 0, 0, 0.5)",
  SHADOW_DARK_TRANSPARENT = "rgba(0, 0, 0, 0.1)",
  FREE = "#73CF71"
}


export default Color;
