import React, { useEffect } from "react";
import CheckBox from "src/components/Shared/CheckBox";
import DropDown from "src/components/Shared/DropDown";
import NumberField from "src/components/Shared/NumberField";
import Section from "src/components/Shared/Section";
import TextField from "src/components/Shared/TextField";
import Stack from '@mui/material/Stack';
import Card from "src/components/Shared/Card";
import SimpleNumberField from "src/components/Shared/SimpleNumberField";
import Grid from '@mui/material/Unstable_Grid2';
import { ProductType } from "src/constants/types";
import { useProjectRequirements } from "src/store/RequirementsStore";
import OperationsPerDayTooltip from "src/tooltips/OperationsPerDayTooltip";
import Box from '@mui/material/Box';
import { TitleStepText } from "src/components/Shared/TitleStepText";
import { TitleDescriptionText } from "src/components/Shared/TitleDescriptionText";
import { useTranslation } from "react-i18next";



export default function ProjectRequirementsCard() {
    const projectSpecs = useProjectRequirements((state) => state.specs );
    const setSpecs = useProjectRequirements((state) => state.setSpecs );
    const setProductType = useProjectRequirements( (state) => state.setProductType );
    const setProductVariant = useProjectRequirements( (state) => state.setProductVariant );
    const setOpPerDay = useProjectRequirements( (state) => state.setOperationsPerDay );
    const setPhotocells = useProjectRequirements( (state) => state.setPhotocells );
    const setBrain = useProjectRequirements( (state) => state.setBrain );
    const setLoop = useProjectRequirements( (state) => state.setLoop );
    const setRfRemote = useProjectRequirements( (state) => state.setRfRemote );
    const setTimer = useProjectRequirements( (state) => state.setTimer );
    const setLeds = useProjectRequirements( (state) => state.setLeds );

    const {t} = useTranslation("common");

    
    function GetOptionsForProductType( productType : ProductType ) {
        if( productType === "Parklio Gate" ) {
            return ["3m", "4m", "5m", "6m", "7m", "8m"];

        } else if( productType === "Parklio Chain" ) {
            return ["5m", "10m", "15m", "20m"];

        } else if( productType === "Parklio Bollard" ){
            return ["400", "600", "800"];

        } else {
            return [t('req.orig_battery'), t('req.double_battery')];
        }
    }

    function GetVariantTextForProductType( productType : ProductType ) {
        if( productType === "Parklio Gate" ) {
            return t('req.tooltip.gate')
    
        } else if( productType === "Parklio Bollard" ) {
            return t('req.tooltip.bollard')

        } else if( productType === "Parklio Chain" ) {
            return t('req.tooltip.chain')
        } else {
            return t('req.tooltip.barrier');
        }
    }

    function GetVariantLabelForProductType( productType : ProductType ) {
        if( productType === "Parklio Gate" ) {
            return t('req.arm_length') ;

        } else if( productType === "Parklio Bollard" ) {
            return t('req.bollard_height');

        } else if( productType === "Parklio Chain" ) {
            return t('req.chain_length');

        } else {
            return t('req.battery');
        }
    }

    function getTranslatedProductType( productType : string ) {
        if( productType == "Parklio Gate" ) {
            return t('app.parklio_gate') ;

        } else if( productType == "Parklio Bollard" ) {
            return t('app.parklio_bollard');

        } else if( productType == "Parklio Chain" ) {
            return t('app.parklio_chain');

        } else {
            return t('app.parklio_barrier');
        }
    } 

    function getTypeFromTranslation( productTypeTr : string ) : ProductType {
        if( productTypeTr == t('app.parklio_gate') ) {
            return 'Parklio Gate' ;

        } else if( productTypeTr == t('app.parklio_bollard') ) {
            return 'Parklio Bollard';

        } else if( productTypeTr == t('app.parklio_chain') ) {
            return 'Parklio Chain';

        } else {
            return 'Parklio Barrier';
        }
    }    


    useEffect( () => {
        const variants = GetOptionsForProductType(projectSpecs.productType)
        if( !variants.includes( projectSpecs.productVariant ) ) {
            //@ts-ignore
            setProductVariant( variants[0] );
        }

    }, [projectSpecs]);


    return (
        <Box style={{width:"100%", paddingBottom:"1rem", paddingTop: "1rem"}} >
            <div style={{display:"flex", flexDirection:"row"}} >
                <TitleStepText>{t('req.step_2')}&nbsp;&nbsp;</TitleStepText>
                <TitleDescriptionText>{t('req.instructions')}</TitleDescriptionText>
            </div>

            <div style={{marginTop:"1.5rem"}} >
                <Stack 
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 2, sm: 2, md: 4 }} 
                    style={{marginTop:"1rem"}}
                >
                    <DropDown
                        label={t('req.product_type')}
                        name="productType"
                        options={[
                            t('app.parklio_gate'),
                            t('app.parklio_chain'),
                            t('app.parklio_bollard'),
                            t('app.parklio_barrier')
                        ]}
                        value={ getTranslatedProductType(projectSpecs.productType) }
                        //@ts-ignore
                        onChanged={ (value) => {
                            setProductType ( getTypeFromTranslation( value ) );
                        }}
                        style={{minWidth:"15rem"}}
                    />

                    <DropDown
                        label={GetVariantLabelForProductType(projectSpecs.productType)}
                        name="productVariant"
                        tooltip= {GetVariantTextForProductType(projectSpecs.productType)}   
                        options={GetOptionsForProductType(projectSpecs.productType)}
                        value={projectSpecs.productVariant}
                        //@ts-ignore
                        onChanged={ setProductVariant }
                        style={{minWidth:"15rem"}}
                    />
                </Stack>

                <SimpleNumberField
                    width="m"
                    min={0}
                    max={9999}
                    integer
                    value={projectSpecs.operationsPerDay}
                    onChanged={ setOpPerDay }
                    label={t('req.operations_per_day')}
                    name="operationsPerDay"
                    tooltip={t('req.tooltip.opperday')}
                />

                { projectSpecs.productType !== "Parklio Barrier" &&
                    <Grid container spacing={{ xs: 0.5, md: 0.5 }} columns={{ xs: 4, md: 8 }} >
                    
                        <Grid xs={4} md={4}>      
                            <CheckBox
                                label={t('req.photocells')}
                                name="photocells"
                                tooltip={t('req.tooltip.photocells')}
                                value={projectSpecs.photocells}
                                onChanged={setPhotocells}
                            />
                        </Grid>

                        { projectSpecs.productType !== "Parklio Chain" &&
                            <Grid xs={4} md={4}>    
                                <CheckBox
                                    label={t('req.magnetic_loop')}
                                    name="loop"
                                    tooltip={t('req.tooltip.magnetic_loop')}
                                    value={projectSpecs.loop}
                                    onChanged={setLoop}
                                />
                            </Grid>
                        }
                        <Grid xs={4} md={4}> 
                            <CheckBox
                                label={t('req.rf_remote')}
                                name="loop"
                                tooltip={t('req.tooltip.rf_remote')}
                                value={projectSpecs.rfRemote}
                                onChanged={setRfRemote}
                            />
                        </Grid>

                        { projectSpecs.productType !== "Parklio Chain" &&
                            <Grid xs={4} md={4}> 
                                <CheckBox
                                    label={t('req.timer')}
                                    name="timer"
                                    tooltip={t('req.tooltip.timer')}
                                    value={projectSpecs.timer}
                                    onChanged={setTimer}
                                />
                            </Grid>
                        }
                        <Grid xs={4} md={4}> 
                            <CheckBox
                                label={projectSpecs.productType==="Parklio Gate" ? t('req.arm_led_lights') : t('req.safety_light')}
                                name="leds"
                                tooltip={t('req.tooltip.lights')}
                                value={projectSpecs.leds}
                                onChanged={setLeds}
                            />
                        </Grid>                        
                        {/* <Grid xs={4} md={4}> 
                            <CheckBox
                                label="Parklio Brain"
                                name="brain"
                                tooltip="Comes by default for all products, enables BLE control..."
                                value={projectSpecs.brain}
                                onChanged={setBrain}
                            />
                        </Grid>   */}        
                                    
                    </Grid>
                }
            </div>
        </Box>
    );
}
