import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { createGlobalStyle } from "styled-components";
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import common_hr from "./translations/hr/common.json";
import common_en from "./translations/en/common.json";
import common_fr from "./translations/fr/common.json";
import common_de from "./translations/de/common.json";

import "./assets/fonts/ibm-plex-sans/style.css";

const GlobalStyle = createGlobalStyle`
    body {
        margin: 0;
        box-sizing: border-box;
        font-family: 'IBM Plex Sans';
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }

    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
    }
`;

i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: 'en',                              // language to use
  resources: {
      en: {
          common: common_en               // 'common' is our custom namespace
      },
      hr: {
          common: common_hr
      },
      fr: {
        common: common_fr
      },
      de: {
        common: common_de
      },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle />
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider> 
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
