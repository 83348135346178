
import React, { useMemo, useState, useRef, useCallback, useEffect } from "react";
import ProjectRequirementsCard from "./ProjectRequirementsCard";
import MountAngleCard from "./MountAngleCard";
import SelectLocationCard from "./SelectLocationCard";
import ResultsCard from "./ResultsCard";

import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { useTranslation } from "react-i18next";
import Color from "src/constants/Shared/Color";
import { ReorderRounded } from "@mui/icons-material";
import { useProjectRequirements } from "src/store/RequirementsStore";
import Footer from "./Footer";

export default function DashboardView() {
    const [isLoading, setIsLoading] = useState(false);
    const projectSpecs = useProjectRequirements((state) => state.specs );

    return ( 
        <div style={{display: "flex", flexDirection: "column", paddingBottom:"1rem", paddingTop:"2.5rem"}}>
          <IntroductionCard/>
          <StyledDivider/>
          <SelectLocationCard/>
          <StyledDivider/>
          <ProjectRequirementsCard />
          { projectSpecs.productType != "Parklio Barrier" && <StyledDivider/>}
          <MountAngleCard />
          <ResultsCard />
          <StyledDivider/>
          <Footer/>
        </div>
    );
}


function IntroductionCard() {
  const {t} = useTranslation("common");
  
  return(
    <>
      <Typography
        variant="h5"
        sx={{ fontFamily:"IBM Plex Sans", fontWeight: 700, marginTop:"0.125rem"}}
      >
        {t('app.title')}
      </Typography>
      <Typography
        variant="body1"
        sx={{ fontFamily:"IBM Plex Sans",marginTop:"1rem", marginBottom:"1rem"}}
      >
        {t('app.description')}
    </Typography>
    </>
  )
}

function StyledDivider() {
  return( 
    <Divider sx={{bgcolor:(Color.DIVIDER), borderBottomWidth:1, marginTop:"0.5rem", marginBottom:"0.5rem"}}/>
  );
}