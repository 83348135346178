import React from "react";
import styled from 'styled-components';
import Main from "../../components/Shared/Main";
import DashboardNavbar from "./DashboardNavbar"
import DashboardView from "./DashboardView";

export default function Dashboard() {
  return (
      <Main>
        <DashboardNavbar/> 
        <DashboardView/>
      </Main>
  );
}
