
import React from "react";
import { useTranslation } from "react-i18next";
import { TitleDescriptionText } from "src/components/Shared/TitleDescriptionText";
import { TitleStepText } from "src/components/Shared/TitleStepText";



export default function DisclaimerTooltip() {
  const {t} = useTranslation("common");
  
  return(
    <>
        <TitleStepText>{t('res.disclaimer_bold')}:&nbsp;</TitleStepText>
        <TitleDescriptionText>{t('res.disclaimer_text')}</TitleDescriptionText>
    </>
  )
}

