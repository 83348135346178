import React from "react";
import HTMLIds from "../../constants/Shared/HTMLIds";
import styled from "styled-components";
import Color from "../../constants/Shared/Color";

interface ContainerProps {
  children: React.ReactNode;
}

export default function Container (props: ContainerProps) {
  const { children } = props;

  return (
    <StyledWrapper id={HTMLIds.CONTAINER}>
      <StyledContainer>{children}</StyledContainer>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
    width: 100vw;
    height: 100vh;
    overflow-x: hidden; 
`;

const StyledContainer = styled.div`
    width: 100%;
    overflow-x: auto;
    min-height: 100%;
    position: relative;
    background-color: ${Color.BACKGROUND};
    display: flex;
`;
