import { BollardTypes, ChainTypes, GateTypes, ProductType } from 'src/constants/types';
import ProjectRequirements from 'src/models/ProjectRequirements';
import { create } from 'zustand';

export const useProjectRequirements = create<{
    specs: ProjectRequirements;

    setSpecs: (specs: ProjectRequirements) => void;
    setProductType: (type: ProductType) => void;
    setProductVariant: (variant: GateTypes | ChainTypes | BollardTypes ) => void;
    setOperationsPerDay: (value : number) => void;
    setPhotocells: (value: boolean) => void;
    setLoop: (value: boolean) => void;
    setRfRemote: (value: boolean) => void;
    setTimer: (value: boolean) => void;
    setBrain: (value: boolean) => void;
    setLeds: (value: boolean) => void;

}>( (set) => ({
    specs: {
        productType: 'Parklio Gate',
        productVariant: '6m',
        operationsPerDay: 10,
        photocells: true,
        rfRemote: false,
        loop: false,
        timer: false,
        brain: true,
        leds: false,
        supportPost: false,
        armSkirt: false
    },

    setSpecs: (specs: ProjectRequirements) => set( () => ({ specs }) ),
    setProductType: (type: ProductType) => set( (state) => ({ specs : {...state.specs, productType : type} } ) ),
    setProductVariant: (variant: GateTypes | ChainTypes | BollardTypes ) => set( (state) => ({ specs : {...state.specs, productVariant : variant} } ) ),
    setOperationsPerDay: (value: number) => set( (state) => ({ specs : {...state.specs, operationsPerDay : value} } ) ),
    setPhotocells: (value: boolean) => set( (state) => ({ specs : {...state.specs, photocells : value} } ) ),
    setLoop: (value: boolean) => set( (state) => ({ specs : {...state.specs, loop : value} } ) ),
    setRfRemote: (value: boolean) => set( (state) => ({ specs : {...state.specs, rfRemote : value} } ) ),
    setTimer: (value: boolean) => set( (state) => ({ specs : {...state.specs, timer : value} } ) ),
    setBrain: (value: boolean) => set( (state) => ({ specs : {...state.specs, brain : value} } ) ),
    setLeds: (value: boolean) => set( (state) => ({ specs : {...state.specs, leds : value} } ) ),
}));

