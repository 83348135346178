import React, { useMemo } from "react";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Button from '@mui/material/Button';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import styled from "styled-components";


interface InfoTooltipProps {
    text: React.ReactNode;
    iconColor?: string;
    style?: React.CSSProperties;
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 480,
    border: '1px solid #dadde9',
  },
}));

export default function InfoTooltip(props: InfoTooltipProps) {
  const {
    text,
    iconColor,
    style
  } = props;

  return (
    <HtmlTooltip title={text} style={{...style}} >
        { iconColor != undefined ? 
          <InfoOutlinedIcon style={{ color: iconColor, ...style }}/>
        : <InfoOutlinedIcon color="disabled"/> }
    </HtmlTooltip>
  );
}
