import React, { forwardRef, useMemo } from "react";
import styled from "styled-components";
import Color from "../../constants/Shared/Color";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { useField } from "formik";
import { boolean } from "yup";
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect'
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InfoTooltip from "./InfoTooltip";

interface DropDownProps {
    name: string;
    label: string;
    tooltip?:string;
    readonly?: boolean;
    disabled?: boolean;
    style?: React.CSSProperties;
    options: string[];
    value: string;
    
    onChanged?: (value: string) => void;
}

  
export default function DropDown(props: DropDownProps) {
    const {
      options,
      label,
      name,
      readonly,
      disabled,
      tooltip,
      style,
      value,
      onChanged
    } = props;

    const [selectedValue, setSelectedValue] = React.useState('');

    const readonlyValue = useMemo(() => {
      if (!value) {
        return "N/A";
      }
      return value;
    }, [value]);

    const handleChange = (event: SelectChangeEvent) => {
        console.log("Selected: ", event)
        setSelectedValue(event.target.value as string);
        if( onChanged != undefined ) {
            onChanged(event.target.value as string);
        }
    };

    return (
        <StyledWrapper style={{...style}}>
            <FormControl
                style={{width:"15rem"}}
                size="small"
            >
                <InputLabel >
                    {label}
                </InputLabel>

                <Select
                    value={ options.includes(value) ? value : options[0] }
                    label={label}
                    onChange={handleChange}
                    style={{backgroundColor: Color.BACKGROUND_LIGHTEST}}
                >
                    {options.map((value) => (
                        <MenuItem
                            key={value}
                            value={value}
                        >
                            {value}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            { tooltip != undefined && (
                <InfoTooltip text={tooltip} style={{marginLeft: "0.375rem", marginTop: "0.5rem"}} />
            )}
        </StyledWrapper>
    )
}


const StyledWrapper = styled.div`
    display: flex;
    align-items: start;
    flex-direction:row;

    margin: 1.25rem 0rem 0rem 0rem;
    width: 100%;
    flex-direction: row;
  }
`;