import CheckBox from "src/components/Shared/CheckBox";
import Section from "src/components/Shared/Section";
import SimpleNumberField from "src/components/Shared/SimpleNumberField";
import Stack from '@mui/material/Stack';
import Card from "src/components/Shared/Card";
import { useOptimizeMountAngleStore } from "src/store/OptimizeMountAngleStore";
import { useEffect, useState } from "react";
import { useSelectedLocation } from "src/store/LocationStore";
import { useProjectRequirements } from "src/store/RequirementsStore";
import Box from '@mui/material/Box';
import { TitleStepText } from "src/components/Shared/TitleStepText";
import { TitleDescriptionText } from "src/components/Shared/TitleDescriptionText";
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { useTranslation } from "react-i18next";

export default function MountAngleCard() {
    const optimizeAngle     = useOptimizeMountAngleStore( (s) => s.optimize )
    const setOptimizeAngle  = useOptimizeMountAngleStore((s) => s.setOptimize)
    const azimuth           = useOptimizeMountAngleStore( (s) => s.azimuth )
    const setAzimuth        = useOptimizeMountAngleStore((s) => s.setAzimuth)
    const slope             = useOptimizeMountAngleStore( (s) => s.slope )
    const setSlope          = useOptimizeMountAngleStore((s) => s.setSlope)
    const location          = useSelectedLocation( state => state.location );
    const setAltitude       = useSelectedLocation( state => state.setAltitude );
    const projectSpecs      = useProjectRequirements((state) => state.specs ); 

    const [collapsed, setCollapsed] = useState( true); 

    const {t} = useTranslation("common");


    useEffect( () => {
        // https://re.jrc.ec.europa.eu/api/v5_2/getelevation?lat=-31.401&lon=139.479&js=1

        var request = "https://solar-calculator.parklio.com/api/PVcalc?lat=" + location.latitude 
            + "&lon=" + location.longitude
            + "&peakpower=0.1&loss=14&optimalangles=1&outputformat=json";

        console.log("Getting best angle information: " + request);

        fetch(request)
            .then( response => {
                if (!response.ok) {
                    if( response.status == 400 && response.statusText.includes("sea") ) {
                        console.log("Sea error!");
                        // ToDo
                    }

                    throw new Error("HTTP error " + response.status);
                }
                return response.json();
            })
            .then( json => {  
                if( optimizeAngle && projectSpecs.productType != "Parklio Barrier") {
                    setAzimuth( json.inputs.mounting_system.fixed.azimuth.value )
                    setSlope( json.inputs.mounting_system.fixed.slope.value )
                }

                //@ts-ignore
                setAltitude( json.inputs.location.elevation ) 
            } )
            .catch(error => console.error(error));
    }, [location, optimizeAngle, projectSpecs.productType] ); // TODo invoke only if barrier 

    useEffect( () => {
        if( projectSpecs.productType == "Parklio Barrier" ) {
            setAzimuth(0);
            setSlope(0);
            console.log("Changed to barrier, slope 0")
        } else {

        }
    }, [projectSpecs.productType]);


    const toggle = () => {
        setCollapsed(!collapsed);
    };

    return (
        projectSpecs.productType != "Parklio Barrier" ?
            <Box style={{width:"100%", paddingBottom:"1rem", paddingTop: "1rem"}} >
                <div style={{display:"flex", flexDirection:"row"}} onClick={toggle} >
                    <TitleStepText>{t('mount.step')}&nbsp;&nbsp;</TitleStepText>
                    <TitleDescriptionText>{t('mount.instructions')}</TitleDescriptionText>
                    { !collapsed ? <ExpandLessOutlinedIcon style={{marginLeft:"1rem"}}/> : < ExpandMoreOutlinedIcon style={{marginLeft:"1rem"}}/>}
                </div>
                { !collapsed && <>
                    <CheckBox
                        label={t('mount.optimize_angles')}
                        name="optimize"
                        tooltip={t('mount.tooltip.optimize')}
                        value={optimizeAngle}
                        onChanged={setOptimizeAngle}
                    />

                    <Stack 
                        direction={{ xs: 'column', md: 'row' }}
                        spacing={{ xs: 2, sm: 2, md: 3 }} 
                        style={{marginTop:"1rem"}}
                    >
                        <SimpleNumberField
                            width="m"
                            label={t('mount.azimuth')}
                            name="azimuth"
                            disabled={optimizeAngle}
                            value={azimuth}
                            onChanged={setAzimuth}
                            tooltip={t('mount.tooltip.azimuth')}
                            unit='°'
                        />
                        <SimpleNumberField
                            width="m"
                            label={t('mount.slope')}
                            name="slope"
                            disabled={optimizeAngle}
                            value={slope}
                            onChanged={setSlope}
                            tooltip={t('mount.tooltip.slope')}
                            unit='°'
                        />
                    </Stack>
                    </>
                }
            </Box>
            :
            <></>
    );
}
