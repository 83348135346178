import CheckBox from "src/components/Shared/CheckBox";
import Section from "src/components/Shared/Section";
import SimpleNumberField from "src/components/Shared/SimpleNumberField";
import Stack from '@mui/material/Stack';
import Card from "src/components/Shared/Card";
import { BarChart } from '@mui/x-charts/BarChart';
import RenderLabelValueList, { LabelValuePair } from "src/components/Shared/LabelValueList";
import Color from "src/constants/Shared/Color";
import { useSelectedLocation } from "src/store/LocationStore";
import { useProjectRequirements } from "src/store/RequirementsStore";
import { calculateAutonomySeconds, calculateConsumptionInADayWh, getBatteryCapacityWh, getBatteryCutoffSoCLevel, getBatteryTypeDescription, secondsToDayHours } from "src/services/ConsumptionCalulator";
import { useEffect, useState } from "react";
import { useOptimizeMountAngleStore } from "src/store/OptimizeMountAngleStore";
import { calculateBatteryState } from "src/services/BatteryStateCalculator";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { MonthlyData } from "src/models/MonthlyData";
import { useTranslation } from "react-i18next";
import { TitleStepText } from "src/components/Shared/TitleStepText";
import { TitleDescriptionText } from "src/components/Shared/TitleDescriptionText";
import InfoTooltip from "src/components/Shared/InfoTooltip";
import DisclaimerTooltip from "src/tooltips/DisclaimerTooltip";
import styled from "styled-components";

const StyledTitle = styled.div`
    margin: 0.125rem 0.3125rem;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
`;


export default function ResultsCard() {
    const project           = useProjectRequirements((state) => state.specs );
    const location          = useSelectedLocation( state => state.location );
    const optimizeAngle     = useOptimizeMountAngleStore( (s) => s.optimize );
    const azimuth           = useOptimizeMountAngleStore( (s) => s.azimuth );
    const slope             = useOptimizeMountAngleStore( (s) => s.slope );
    const altitude          = useSelectedLocation( state => state.altitude )

    const [calledOnce, setCalledOnce] = useState(false);
    
    const [ daysEmpty, setDaysEmpty ] = useState(new Array<number>);
    const [ daysFull, setDaysFull ] = useState(new Array<number>);
    const [ daysPartial, setDaysPartial ] = useState(new Array<number>);
    const [ resultHelpText, setResultHelpText ] = useState("");
    const [ monthlyData, setMonthlyData] = useState( new Array<MonthlyData>);

    const {t} = useTranslation("common");

    function round(value : number , precision : number) {
        var multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    }

    const xLabels = [
        t('app.january'),
        t('app.february'), 
        t('app.march'), 
        t('app.april'), 
        t('app.may'), 
        t('app.june'), 
        t('app.july'), 
        t('app.august'), 
        t('app.september'), 
        t('app.october'), 
        t('app.november'), 
        t('app.december')
    ];
    const daysInMonthAvg = [31, 28.25, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    const updateOffGridResult = () : void => {
        calculateBatteryState( project, location, azimuth, slope )
        .then( (value) => {
            let de : number []= [];
            let df : number []= [];
            let dp : number []= [];

            setMonthlyData( value );

            if( value.length == 12 ) {
                for( let idx = 0; idx < value.length; idx ++) {
                    const empty =  round ( value[idx].percentDaysEmpty / 100 * daysInMonthAvg[idx], 1 );
                    const full = round ( value[idx].percentDaysFull / 100 * daysInMonthAvg[idx], 1);

                    de.push( empty );
                    df.push( full );
                    dp.push( daysInMonthAvg[idx] - empty - full);
                }
            }

            setDaysEmpty(de);
            setDaysFull(df);
            setDaysPartial(dp);
        })
        .catch((e) => {console.log(e);});
    }

    useEffect( () => {
        if( optimizeAngle == true ) {
            setCalledOnce(false);
        }
    }, [optimizeAngle])

    useEffect( () => {
        if( optimizeAngle == false ) {
            calculateBatteryState( project, location, azimuth, slope );
        } else {
            setCalledOnce(false);
        }
    }, [location])

    /* When not using optimization update on any change */
    useEffect( () => {
        /* Update on any change or when the product is parklio barrier */
        if( !optimizeAngle || project.productType == "Parklio Barrier") {
            updateOffGridResult();
        }
    }, [altitude])   

    /* When using only update on slope changed since it is changed after azimuth */
    useEffect( () => {
        if( optimizeAngle && !calledOnce ) {
            updateOffGridResult();
            setCalledOnce(true);
        }
    }, [slope])   

    useEffect( () => {
        updateOffGridResult();
    }, [project])   

    
    const systemInfoData: LabelValuePair[] = [
        {
            label: t('res.longitude'),
            value: location.longitude.toFixed(6) + ' °',
            tooltip: t('res.tooltip.longitude')
        },
        {
            label: t('res.latitude'),
            value: location.latitude.toFixed(6) + ' °',
            tooltip: t('res.tooltip.latitude')
        },
        {
            label: t('res.panel_info'),
            value: '2 x 50W',
            tooltip: t('res.tooltip.panel_info')
        },
        {
            label: t('res.panel_azimuth'),
            value: azimuth.toString() + ' °',
            tooltip: t('res.tooltip.panel_azimuth')
        },
        {
            label: t('res.panel_slope'),
            value: slope.toString() + ' °',
            tooltip: t('res.tooltip.panel_slope')
        },
        {
            label: t('res.battery_type'),
            value: getBatteryTypeDescription(project),
            tooltip: t('res.tooltip.battery_type')
        },
        {
            label: t('res.battery_capacity'),
            value: getBatteryCapacityWh(project).toString() + " Wh",
            tooltip: t('res.tooltip.battery_capacity')
        },
        {
            label: t('res.battery_cutoff'),
            value: getBatteryCutoffSoCLevel(project).toString() + " %",
            tooltip: t('res.tooltip.battery_cutoff')
        },
        {
            label: t('res.daily_consumption'),
            value: calculateConsumptionInADayWh(project).toFixed(2) + " Wh",
            tooltip: t('res.tooltip.daily_consumption')
        },
        {
            label: t('res.autonomy'),
            value: secondsToDayHours( calculateAutonomySeconds(project) ),
            tooltip: t('res.tooltip.autonomy')
        }
    ]
        
    return (
        <Box sx={{ borderRadius: 3 }}  
            style={{
                marginBottom:"1rem", 
                marginTop:"1rem", 
                paddingLeft:"2rem", 
                paddingRight:"2rem", 
                paddingTop:"1rem",
                paddingBottom:"1rem",
                display: "flex", 
                flexDirection:"column",
                backgroundColor:(Color.RESULT_BACKGROUND)}
        }>
            

            <StyledTitle >
                <Typography variant="h6" color={Color.TEXT_RESULT} sx={{ fontWeight: 550}} >
                    {t('res.title')}
                </Typography>
                <InfoTooltip iconColor={Color.RESULT_ICON} text={<DisclaimerTooltip/>} style={{marginLeft: "1.2rem", marginTop:"0.125rem"}} />
            </StyledTitle>
        

            <Stack 
                direction={{ sm: 'column', md: 'row' }}
                spacing={{ xs: 1, sm: 1, md: 8 }} 
                style={{marginTop:"1rem"}}
            >   

                { RenderLabelValueList( systemInfoData) }

                <div style={{height:"30rem", width:"100%"}}>
                    <BarChart
                        sx={{
                            "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel":{
                                strokeWidth:1,
                                fill:Color.CHART_AXIS
                            },
                            // change all labels fontFamily shown on both xAxis and yAxis
                            "& .MuiChartsAxis-tickContainer .MuiChartsAxis-tickLabel":{
                                fontFamily: "IBM Plex Sans",
                                color:Color.TEXT_RESULT
                            },
                            // change bottom label styles
                            "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel":{
                                strokeWidth:"1",
                                fill:Color.CHART_AXIS
                            },
                            // bottomAxis Line Styles
                            "& .MuiChartsAxis-bottom .MuiChartsAxis-line":{
                                stroke:Color.CHART_AXIS,
                                strokeWidth:1
                            },
                            // leftAxis Line Styles
                            "& .MuiChartsAxis-left .MuiChartsAxis-line":{
                                stroke:Color.CHART_AXIS,
                                strokeWidth:1
                            },
                            "& .MuiChartsAxis-tickContainer .MuiChartsAxis-tick":{
                                stroke:Color.CHART_AXIS,
                                strokeWidth:1
                            }
                        }}
                        slotProps={{
                            legend: {
                                itemGap:40,
                                labelStyle:{
                                    fontFamily:"IBM Plex Sans",
                                    fill:Color.TEXT_RESULT
                                }
                            }
                        }}
                        series={[
                            { data: daysEmpty, stack:'A', label: t('res.days_with_empty'), id: 'de', color: Color.BATTERY_EMPTY },
                            { data: daysFull, stack:'A', label: t('res.days_with_full'), id: 'df', color: Color.BATTERY_FULL },
                            { data: daysPartial, stack:'A', label: t('res.days_with_partial'), id: 'dp', color: Color.BATTERY_PARTIAL },
                        ]}
                        xAxis={[{ data: xLabels, scaleType: 'band' }]}
                    />
                </div>
            </Stack> 

            <Typography variant="h6" color={Color.TEXT_RESULT} sx={{ fontWeight: 550, my: 2, marginBottom: "0.5rem"}}>
                {t('res.how_to_interpret_title')}
            </Typography>

            <Typography variant="body1" color={Color.TEXT_RESULT} sx={{ fontWeight: 500, my: 2 }}>
                {t('res.how_to_interpret')}
            </Typography>

        </Box>
    );
}
