import ProjectRequirements from "src/models/ProjectRequirements";
import { calculateConsumptionInADayWh, getBatteryCapacityWh, getBatteryCutoffSoCLevel, getPvPeakPowerW } from "./ConsumptionCalulator";
import { Coordinates } from "src/models/Location";
import { MonthlyData } from "src/models/MonthlyData";


async function getPvOffGridData( request : string ) {
    
    try {
        let response = await fetch(request);
        if (!response.ok) 
            throw response.statusText;
        return await response.json(); 
    } catch (e) {
        console.error(e);
        return null
    }
  }

export async function calculateBatteryState(
    requirements : ProjectRequirements, 
    location : Coordinates,
    azimuth : number,
    slope : number
) : Promise<MonthlyData[]>
{
    const cutoff = getBatteryCutoffSoCLevel(requirements);
    const capacityWh = getBatteryCapacityWh(requirements);
    const pvPeakPower = getPvPeakPowerW(requirements);
    const consumptionDay = calculateConsumptionInADayWh(requirements);

    let results: MonthlyData[] = [];

    var request = "https://solar-calculator.parklio.com/api/SHScalc?"
        + "lat=" + location.latitude 
        + "&lon=" + location.longitude
        + "&usehorizon=1"
        + "&angle=" + slope
        + "&aspect=" + azimuth
        + "&peakpower=" + pvPeakPower
        + "&batterysize=" + capacityWh
        + "&cutoff=" + cutoff
        + "&consumptionday=" + consumptionDay
        + "&outputformat=json";


    let jsonResult = await getPvOffGridData(request);

    if( jsonResult != null ) {
        //@ts-ignore
        var data = jsonResult.outputs.monthly;
                
        for( var idx = 0; idx < data.length; idx ++ ) {
            results.push( 
                {
                    energyCapturedWhDay :   data[idx].E_d,
                    month:                  data[idx].month,
                    energyNotCapturedWhDay: data[idx].E_lost_d,
                    percentDaysEmpty:       data[idx].f_e,
                    percentDaysFull:        data[idx].f_f
                }
            )
        }

    }

    return results ;
}