import React, { useCallback, useEffect, useState } from "react";
import styled from 'styled-components';
import Color from "src/constants/Shared/Color";
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InfoTooltip from "./InfoTooltip";

const Div = styled.div`
  pointer-events: auto;
  background: ${Color.RESULT_BACKGROUND};
  border-radius: 0.25rem;
  padding: 0.125rem;
  display: flex;
  margin-top: 0.75rem;
  align-items: start;
  border: 0.0625rem solid var(--color-borderPrimary);
`;

const Ul = styled.ul`
  width: 100%;
  margin: 0px;
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction:column;
  grid-gap: 0.15rem;
  align-items: start;
`;

const Li = styled.li`
    width: 100%;
    margin: 0.125rem 0.3125rem;
    list-style: none;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
`;

const PropertyName = styled.label`
    font-weight: normal;
    justify-content: flex-start;
    color: ${Color.TEXT_RESULT};
    font-family: IBM Plex Sans;
    font-style: normal;
    font-size: 0.875rem;
    line-height: 1.1875rem;
    flex-direction: row;
    align-items: center;
    min-width: 14rem;
    width: 14rem;
    height: 1.375rem;
    display: flex;
`;

const PropertyValue = styled.label`
    font-weight: bold;
    justify-content: flex-start;
    color: ${Color.TEXT_RESULT};
    font-family: IBM Plex Sans;
    font-style: normal;
    font-size: 0.875rem;
    line-height: 1.1875rem;
    flex-direction: row;
    align-items: center;
    min-width: 10rem;
    height: 1.375rem;
    display: flex;
`;


export interface LabelValuePair {
    label: string
    value: string
    tooltip?: React.ReactNode
}


export default function RenderLabelValueList(  data: LabelValuePair[] ) {
    return(
            <Div>
                <Ul>
                    { data.map( ({label, value, tooltip}) => {
                        return(
                            <Li key={label+value}>
                                <PropertyName> {label} </PropertyName>
                                <PropertyValue> {value} </PropertyValue>
                                { tooltip != undefined && (
                                    <InfoTooltip iconColor={Color.RESULT_ICON} text={tooltip} />
                                )}
                            </Li>
                        );
                    })}
                </Ul>
            </Div>
    );
}

