import { useCallback, useState } from "react";
import { Routes, Route, Navigate, useLocation, redirect , useNavigate, NavLink, BrowserRouter } from "react-router-dom";
import Container from "../components/Shared/Container";
import Dashboard from "../screens/Home/Dashboard";
import styled, { css } from "styled-components";
import Color from "src/constants/Shared/Color";
import NotFoundPage from "src/screens/Error/NotFoundPage";
import { useTranslation } from "react-i18next";
import LanguagePath from "./LanguagePath";
import { DEFAULT_LANGUAGE } from "src/constants/Constants";

export default function IndexRoutes() {
  const location = useLocation();

  return (
      <div style={{ display: 'flex', height: '100%' }}>
        <Container>
            <Routes>
              <Route path="/" element={<Navigate to={"/" + DEFAULT_LANGUAGE} /> } />
              <Route path="/:lang" element={<LanguagePath />}>
                <Route index element={ <Dashboard /> }/>
              </Route>
              <Route path="*" element={ <NotFoundPage /> } />
            </Routes>
        </Container>      
      </div>
  );

}
