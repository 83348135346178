import ProjectRequirements from "src/models/ProjectRequirements";



export function calculateIdleCurrent(requirements : ProjectRequirements) : number {
    let idleConsumption = 0;
    const canHaveTimer  =  requirements.productType != "Parklio Chain";
    const canHaveLoop   =  requirements.productType != "Parklio Chain";
    const canHavePc     =  requirements.productType != "Parklio Barrier";
    const canHaveLeds   =  requirements.productType != "Parklio Barrier";
    const canHaveBrain  =  requirements.productType != "Parklio Barrier";
    const canHaveRf     =  requirements.productType != "Parklio Barrier";


    /*
    * Calculate consumption in idle operation.
    */
    switch( requirements.productType ){
    case "Parklio Gate":
        idleConsumption += 0.045;
        break;
    case "Parklio Chain":
        idleConsumption += 0.125;
        break;
    case "Parklio Bollard":
        idleConsumption += 0.086;
        break;
    case "Parklio Barrier":
        idleConsumption += 0.000125; // 125uA is average
        break;
    default:
        break;
    }


    if( canHavePc && requirements.photocells ) {
        idleConsumption += 0.025;
    
        /* Double cells for bollard */
        if( requirements.productType == "Parklio Bollard" )
          idleConsumption += 0.025;
    }

    
    if( canHaveLoop && requirements.loop ) {
        idleConsumption += 0.033;
    }

    if( canHaveTimer && requirements.timer  ) {
        idleConsumption += 0.04;
    }

    if( canHaveLeds && requirements.leds ) {
        if( requirements.productType == "Parklio Bollard" )
            idleConsumption += 0.1;
        else if( requirements.productType == "Parklio Chain" )
            idleConsumption += 0.1; 
        else
            idleConsumption += 0.1;
    }

    if( canHaveBrain && requirements.brain ) {
        idleConsumption += 0.005;
    }

    if( canHaveRf && requirements.rfRemote ) {
        idleConsumption += 0.0125;
    }

    return idleConsumption;
}

export function calculateAverageActiveCurrent(requirements : ProjectRequirements) : number {
    let activeCurrent = 0;
    let operationTime = 0;

    switch( requirements.productType ){
        case "Parklio Gate":
            switch(requirements.productVariant) {
                case "3m": activeCurrent = 0.75; operationTime = 15; break;
                case "4m": activeCurrent = 0.8; operationTime = 15; break;
                case "5m": activeCurrent = 0.6; operationTime = 25; break;
                case "6m": activeCurrent = 0.6; operationTime = 30; break;
                case "7m": activeCurrent = 0.75; operationTime = 25; break;
                case "8m": activeCurrent = 0.8; operationTime = 30; break;
            }
          break;
    
        case "Parklio Chain":
            switch(requirements.productVariant) {
                case "5m":  activeCurrent = 2; operationTime = 10; break;
                case "10m": activeCurrent = 2.2; operationTime = 11; break;
                case "15m": activeCurrent = 2.6; operationTime = 12; break;
                case "20m": activeCurrent = 3; operationTime = 13; break;
            }
            break;
    
        case "Parklio Bollard":
            switch(requirements.productVariant) {
                case "400": activeCurrent = 6.5; operationTime = 8; break;
                case "600": activeCurrent = 6.5; operationTime = 10; break;
                case "800": activeCurrent = 6.5; operationTime = 13; break;
            }
          break;
    
        case "Parklio Barrier":
          activeCurrent = 2.2; operationTime = 5.5;  // ToDo
          break;

        default:
          break;
    }

    const charge_mAs = activeCurrent * operationTime;
    const charge_daily_mAs = charge_mAs * 2 * requirements.operationsPerDay;
    const average_current = charge_daily_mAs / (24 * 60 * 60);

    return average_current * 1.05; // Add a 5% margin
}

export function getPvPeakPowerW(requirements : ProjectRequirements) : number {
    /* Barrier is under the car for most of the & the PV panel does not have MPPT */
    const barrierEffectivenesFactor : number = 0.25; 

    switch( requirements.productType ){
        case "Parklio Gate":
            return 100;
        case "Parklio Chain":
        case "Parklio Bollard":
            return 100;
        case "Parklio Barrier":
            return barrierEffectivenesFactor * 1.2; // ToDO pitat smoljkana za tocne Watte
    }
}

export function getBatteryCapacityWh(requirements : ProjectRequirements) : number {
    switch( requirements.productType ){
        case "Parklio Gate":
            return 432;
        case "Parklio Chain":
        case "Parklio Bollard":
            return 528;
        case "Parklio Barrier":
            return requirements.productVariant == "Original Battery" ? 23.7 : 47.4;
    }
}

export function getSupplyVoltage(requirements : ProjectRequirements) : number {
    switch( requirements.productType ){
        case "Parklio Gate":
            return 36;
        case "Parklio Chain":
        case "Parklio Bollard":
            return 24;
        case "Parklio Barrier":
            return 7.4;
    }
}

/* 
 * Returns the percentage of the battery that cannot be utilized.
 * When the battery falls bellow this level the system is shut down
 */
export function getBatteryCutoffSoCLevel(requirements : ProjectRequirements) : number {
    switch( requirements.productType ){
        case "Parklio Gate":
        case "Parklio Chain":
        case "Parklio Bollard":
            return 10;
        case "Parklio Barrier":
            return 5;
    }
}

export function getBatteryTypeDescription(requirements : ProjectRequirements) : string {
    switch( requirements.productType ){
        case "Parklio Gate":
            return "3 x 12V 12Ah";
        case "Parklio Chain":
        case "Parklio Bollard":
            return "2 x 12V 22Ah";
        case "Parklio Barrier":
            return requirements.productVariant == "Original Battery" ? "24 Wh 7.2V Li-Ion ..." : "48 Wh "; // ToDo
    }
}



export function calculateConsumptionInADayWh(requirements : ProjectRequirements) : number {
    const idleConsumption = calculateIdleCurrent(requirements);
    const activeConsumption = calculateAverageActiveCurrent( requirements );
    const totalConsumption = idleConsumption + activeConsumption;

    const voltage       = getSupplyVoltage(requirements);
    const consumptionWh = voltage * totalConsumption * 24; 

    return consumptionWh;
}


/* 
 * Assuming a fully charged battery and no input from the solar charger calculates
 * how long can the designed system run based on the provided parameters. 
 */
export function calculateAutonomySeconds(requirements : ProjectRequirements) : number {
    const batteryCutoffLevel = getBatteryCutoffSoCLevel(requirements)
    const effectiveCapacityWh = getBatteryCapacityWh(requirements) * (1.0 - (batteryCutoffLevel / 100));

    const idleConsumption = calculateIdleCurrent(requirements);
    const activeConsumption = calculateAverageActiveCurrent( requirements );
    const totalConsumption = idleConsumption + activeConsumption;

    const voltage = getSupplyVoltage(requirements);
    const consumptionWs = voltage * totalConsumption; 

    const batteryDurationSec = (effectiveCapacityWh / consumptionWs) * 3600;

    return batteryDurationSec;
}



export function secondsToDayHours( seconds : number  ) : string {
    const daysInMonth = 30.437;
    let hours = seconds / (60*60);
    let days = Math.floor(hours / 24);
    let months = Math.floor( days / daysInMonth);


    let result = "";

    if( months > 0 ) {
        result += `${months} ${months === 1 ? 'month' : 'months'}`;
        days = Math.floor(days % daysInMonth);
    }
    if (days > 0) {
        if (months > 0) {
            result += " ";
        }
        result += `${days} ${days === 1 ? 'day' : 'days'}`;
        hours = Math.floor(hours % 24);
    }
    if ( months == 0 && hours > 0) {
        if (days > 0) {
            result += " ";
        }
        result += `${hours} ${hours === 1 ? 'hour' : 'hours'}`;
    }
  
    return result;
}