import React, { useMemo, useState, useRef, useCallback, useEffect } from "react";
import ProjectRequirementsCard from "./ProjectRequirementsCard";
import MountAngleCard from "./MountAngleCard";
import SelectLocationCard from "./SelectLocationCard";
import ResultsCard from "./ResultsCard";
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { useTranslation } from "react-i18next";
import Color from "src/constants/Shared/Color";
import { ReorderRounded } from "@mui/icons-material";
import { useProjectRequirements } from "src/store/RequirementsStore";
import styled from "styled-components";
import SvgIcon from '@mui/icons-material/AddCircleOutlined';
import { ReactComponent as LogoIcon } from 'src/assets/img/Parklio-Logo.svg';

const FooterStyle = styled.div`
  padding: 0.125rem;
  text-align: center;
  height:3rem;
  margin-top:1rem;
`;


export default function Footer() {
    const {t} = useTranslation("common");
    
    return(
        <FooterStyle>
            &copy;&nbsp;{(new Date().getFullYear())}&nbsp;Parklio Ltd. | All Rights Reserved
        </FooterStyle>
        

    )
  }
  