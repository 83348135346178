import React, { useEffect } from 'react';
import styled from 'styled-components';
import Color from "src/constants/Shared/Color";
import Button from '@mui/material/Button';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { ReactComponent as LogoIcon } from 'src/assets/img/Parklio-Logo.svg';
import SvgIcon from '@mui/icons-material/AddCircleOutlined';
import {useTranslation} from "react-i18next";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { changeLanguage } from 'i18next';
import { CircleFlag, CircleFlagLanguage } from 'react-circle-flags'
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { DEFAULT_LANGUAGE } from 'src/constants/Constants';

const NavbarStyle = styled.div`
  padding: 0.125rem;
  overflow: hidden;
  display: flex;
  flex: none;
  align-items: center;
  height:3rem;
`;



export default function DashboardNavbar() {
  const {t} = useTranslation("common");

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
      setMobileOpen((prevState) => !prevState);
  };

  const navItems = [t('navbar.about'), t('navbar.contact_us')];

  const { lang } = useParams();
  const languageCode : string = lang != undefined ? lang : DEFAULT_LANGUAGE;


  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }} display="flex" flexDirection="column">
        <Button size='large' sx={{ color: (Color.TEXT_CARD_TITLE) }} onClick={ () => {window.open(aboutLink, "_blank");} }>
          {t('navbar.about')}
        </Button>
        <Button size='large' sx={{ color: (Color.TEXT_CARD_TITLE) }} onClick={ () => {window.open(contactLink, "_blank");} } >
          {t('navbar.contact_us')}
        </Button>
    </Box>
  );

  const aboutLink = t('link.about');
  const contactLink = t('link.contact');

  return (
    <NavbarStyle>
      <AppBar component="nav">
        <Toolbar  sx={{ bgcolor:(Color.TOOLBAR) }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          > 
            <MenuIcon />
          </IconButton>

          <SvgIcon component={LogoIcon} viewBox="0 0 100 28" sx={{ height: "2.5rem", width:"6rem" }}/>

          <LanguagePicker/>

          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              <Button sx={{ color: (Color.TOOLBAR_TEXT) }} onClick={ () => {window.open(aboutLink, "_blank");} }>
                {t('navbar.about')}
              </Button>
              <Button sx={{ color: (Color.TOOLBAR_TEXT) }} onClick={ () => {window.open(contactLink, "_blank");} } >
                {t('navbar.contact_us')}
              </Button>
          </Box>

        </Toolbar>
      </AppBar>




      <nav>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 256 },
          }}
        >
          {drawer}
        </Drawer>
      </nav>

    </NavbarStyle>
  );
}






export function LanguagePicker() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { t, i18n, ready } = useTranslation();

  const changeLanguage = (languageCode : string) => {
    navigate("/" + languageCode)
  };

  const { lang } = useParams();

  const language : string = lang != undefined ? lang : DEFAULT_LANGUAGE;
  const flagCode : string = language != "en" 
                              ?  (language == "cz" ? "cs" : language)
                              : "en-us";


  return (
    <div  style={{marginLeft:'auto' }}>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={{ color:Color.TEXT_LIGHTEST, height:"2rem" }}

      >
        <CircleFlagLanguage languageCode={flagCode} height="24" />
      </Button>


      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        style={{minWidth: "20rem"}}
      >
        <MenuItem onClick={ () => changeLanguage('en') } > 
          <CircleFlagLanguage languageCode="en-us" height="24" />&nbsp; en
        </MenuItem>
        <MenuItem onClick={ () => changeLanguage('de') }> 
          <CircleFlagLanguage languageCode="de" height="24" /> &nbsp; de
        </MenuItem>
        <MenuItem onClick={ () => changeLanguage('fr') }> 
          <CircleFlagLanguage languageCode="fr" height="24" />&nbsp; fr
        </MenuItem>
        {/* <MenuItem onClick={ () => changeLanguage('it') }> 
          <CircleFlagLanguage languageCode="it" height="24" /> &nbsp; it
        </MenuItem>
        <MenuItem onClick={ () => changeLanguage('hr') }> 
          <CircleFlagLanguage languageCode="hr" height="24" /> &nbsp; hr
        </MenuItem>
        <MenuItem onClick={ () => changeLanguage('es') }> 
          <CircleFlagLanguage languageCode="es" height="24" /> &nbsp; es
        </MenuItem>
        <MenuItem onClick={ () => changeLanguage('pl') }> 
          <CircleFlagLanguage languageCode="pl" height="24" /> &nbsp; pl
        </MenuItem>
        <MenuItem onClick={ () => changeLanguage('nl') }> 
          <CircleFlagLanguage languageCode="nl" height="24" /> &nbsp; nl
        </MenuItem>
        <MenuItem onClick={ () => changeLanguage('sk') }> 
          <CircleFlagLanguage languageCode="sk" height="24" /> &nbsp; sk
        </MenuItem>
        <MenuItem onClick={ () => changeLanguage('cz') }> 
          <CircleFlagLanguage languageCode="cs" height="24" /> &nbsp; cz
        </MenuItem> */}
      </Menu>
    </div>
  );
}