import React, { forwardRef, useMemo } from "react";
import styled from "styled-components";
import Color from "../../constants/Shared/Color";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { useField } from "formik";
import { boolean } from "yup";
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect'
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import InfoTooltip from "./InfoTooltip";

interface CheckBoxProps {
    name: string;
    label: string;
    tooltip?:string;
    disabled?: boolean;
    style?: React.CSSProperties;
    value: boolean;
    onChanged?: (value: boolean) => void;
}

  
export default function CheckBox(props: CheckBoxProps) {
    const {
      label,
      name,
      disabled,
      tooltip,
      style,
      value,
      onChanged
    } = props;

    const [cbValue, setCbValue] = React.useState(value);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCbValue(event.target.checked);
        if( onChanged ) {
            onChanged(event.target.checked);
        }
    };

    return (
        <StyledWrapper style={{...style}}>
            <FormControl
                style={{width:"15rem", minWidth:"15rem"}}
                size="small"
            >
                <FormControlLabel
                    control = {
                        <Checkbox 
                            checked={cbValue}
                            onChange={handleChange}
                            disabled={disabled}
                        />
                    }
                    label={label}        
                />
               
            </FormControl>
            { tooltip != undefined && (
                <InfoTooltip text={tooltip} style={{marginLeft: "0.375rem", marginTop: "0.5rem"}} />
            )}
        </StyledWrapper>
    )
}


const StyledWrapper = styled.div`
    display: flex;
    align-items: start;
    flex-direction:row;

    margin: 0.25rem 0rem 0rem 0rem;
    width: 100%;
    flex-direction: row;
  }
`;