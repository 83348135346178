import React, { useMemo, useState, useRef, useEffect } from "react";
import { MapContainer, Marker, TileLayer, useMap, useMapEvents } from 'react-leaflet'
import "leaflet/dist/leaflet.css"
import marker from 'src/assets/svg/Location.svg';
import { Icon, LatLng, latLng } from 'leaflet'
import { useSelectedLocation } from "src/store/LocationStore";
import Box from '@mui/material/Box';
import { TitleStepText } from "src/components/Shared/TitleStepText";
import { TitleDescriptionText } from "src/components/Shared/TitleDescriptionText";
import { useTranslation } from "react-i18next";

export default function SelectLocationCard() {
    const {t} = useTranslation("common");

    const [hasPosition, setHasPosition] = useState(false);
    const position = useSelectedLocation( state => state.location );
    const setPosition = useSelectedLocation( state => state.setLocation );
    const altitude = useSelectedLocation( state => state.altitude )

    const myIcon = new Icon({
      iconUrl: marker,
      iconSize: [80,80]
    })

    /* Acquire user position only once */
    function AcquirePosition() {
        const map = useMap();
        map.attributionControl.setPrefix("Parklio");

        useEffect(() => {
            if( hasPosition == false ) {
                console.log("Get position: ", hasPosition);

                map.locate().once("locationfound", function (e) {
                    setHasPosition(true);
                    console.log("Found position!");
                    setPosition( {
                        longitude: e.latlng.lng, 
                        latitude: e.latlng.lat 
                    } )
                    map.flyTo(e.latlng, map.getZoom());
                });
            }
        }, []);
    
        return <></>;
    }

    function DraggableMarker() {
        const markerRef = useRef(null)
        const map = useMap();

        const mapEvents = useMapEvents({
            click(e) {
                console.log(e.latlng.lat);
                console.log(e.latlng.lng);
                setPosition( {
                    longitude: e.latlng.lng, 
                    latitude: e.latlng.lat 
                } )
            }
        })

        const eventHandlers = useMemo(
            () => ({
              dragend(e: any) {
                const marker = markerRef.current
                if (marker != null) {
                    console.log(e)

                    if( e.target != null ) {
                        console.log( e.target.getLatLng() )
                        const lat = e.target.getLatLng().lat;
                        const long = e.target.getLatLng().lng;
                        setPosition( {latitude:lat, longitude:long} )
                    }
                }
              },
            }),
            [],
          )

        return (
            <Marker
                icon={myIcon}
                draggable={true}
                position={ [position.latitude, position.longitude]} 
                eventHandlers={eventHandlers}
                ref={markerRef}
            >
            </Marker>
        )
    }


    return(
        <Box style={{width:"100%", paddingBottom:"1rem", paddingTop: "1rem"}} >
            <div style={{display:"flex", flexDirection:"row"}} >
                <TitleStepText>{t('loc.step_1')}&nbsp;&nbsp;</TitleStepText>
                <TitleDescriptionText>{t('loc.select_location')}</TitleDescriptionText>
            </div>

            <MapContainer 
                attributionControl={true} 
                center={ position == undefined ? [0,0] : [position.latitude, position.longitude]} 
                zoom={5} scrollWheelZoom={false} 
                style={{height:"30rem", width:"100%", marginTop:"1rem"}}  
            >
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <AcquirePosition/>
                <DraggableMarker/>
            </MapContainer>

{/*             
            <Stack 
                direction={{ xs: 'column', md: 'row' }}
                spacing={{ xs: 2, sm: 2, md: 3 }} 
                style={{marginTop:"1rem"}}
            >
                <SimpleNumberField
                      width="m"
                      readonly
                      label="Longitude"
                      name="long"
                      decimals={6}
                      value={position.longitude}
                      unit='°'
                />

                <SimpleNumberField
                      width="m"
                      readonly
                      label="Latitude"
                      decimals={6}
                      name="long"
                      unit='°'
                      value={position.latitude}
                />

                <SimpleNumberField
                      width="m"
                      readonly
                      label="Elevation"
                      name="long"
                      unit='m'
                      value={altitude}
                />
                
            </Stack> */}
        </Box>
    )      
}


