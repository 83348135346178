import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { DEFAULT_LANGUAGE, SUPPORTED_LANGS } from "src/constants/Constants";
import Dashboard from "src/screens/Home/Dashboard";

export default function LanguagePath() {
    const { i18n } = useTranslation();
    const { lang } = useParams();
    const navigate = useNavigate();
    //const curPath = location.pathname;
    console.log( "LanguagePath" );
    useEffect(() => {
      console.log( "useEffect" );

      if (lang && i18n.resolvedLanguage !== lang) {
          if( SUPPORTED_LANGS.includes(lang) ) {
            i18n.changeLanguage(lang);
            console.log( "Supported" );
          } else {
            console.log( "navigate" );
            navigate("/"+DEFAULT_LANGUAGE)
          }
  
      }
    }, [lang]);
    
    return <Outlet />;
  }