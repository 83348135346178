import { create } from 'zustand';

export const useOptimizeMountAngleStore = create<{
    optimize: boolean;
    slope : number;
    azimuth : number;
    setOptimize: (value: boolean) => void;
    setSlope: (value: number) => void;
    setAzimuth: (value: number) => void;

}>( (set) => ({
    optimize: true,
    slope: 35,
    azimuth: 0,

    setOptimize: (value: boolean) => set( (optimize) => ({optimize:value}) ),
    setSlope: (value: number) => set( (slope) => ({slope:value}) ),
    setAzimuth: (value: number) => set( (azimuth) => ({azimuth:value}) ),


}));

