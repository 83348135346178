import styled, { css } from "styled-components";

const Main = styled.div<{ sidePadding?: string, topPadding?: string, height?: string }>`
    width: 100%;
    // display: flex;
    // flex-direction: row;
    min-width: 350px;

   

    @media only screen and (min-width:768px) {
      padding: 0 2.5rem;
    }

    @media only screen and (max-width:767px) {
      padding: 0 0.75rem;
    } 
    
    ${(props) => {
        const { sidePadding } = props;

        if (sidePadding) {
            return css`
            padding: 0% ${sidePadding};
          `;
        }

    }
    }
    ${(props) => {
        const { topPadding } = props;

        if (topPadding) {
            return css`
            padding-top:${topPadding};
          `;
        }

    }
    }

    ${(props) => {
      const { height } = props;

      if (height) {
          return css`
          height:${height};
        `;
      }
    }
    }
`;

export default Main;

//  @media (max-width: 80.625rem) {
//   flex-direction: column;
// }