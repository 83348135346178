import React, { useMemo } from "react";
import styled from "styled-components";
import Color from "../../constants/Shared/Color";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { useField } from "formik";
import { boolean } from "yup";
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InfoTooltip from "./InfoTooltip";

export type SimpleNumberFieldWidth = "xs" | "s" | "m" | "l" | "xl";

interface SimpleNumberFieldProps {
  name: string;
  label: string;
  tooltip?:React.ReactNode;
  readonly?: boolean;
  disabled?: boolean;
  unit?: string;
  min?:number;
  max?:number;
  integer?:boolean
  style?: React.CSSProperties;
  value?:number;
  decimals?:number;
  width?: SimpleNumberFieldWidth;
  onChanged?: (value: number) => void;
}

export default function SimpleNumberField(props: SimpleNumberFieldProps) {
  const {
    label,
    name,
    readonly,
    disabled,
    unit,
    tooltip,
    style,
    width,
    value,
    min,
    max, 
    decimals,
    integer,
    onChanged
  } = props;


  const readonlyValue = useMemo(() => {
    if (!value) {
      return "N/A";
    }

    return (decimals != undefined) ? value.toFixed(decimals) : value;
  }, [value]);
  
 
  const widthToPx = (width : string | undefined ) : string => {
    if( width == undefined )
      return "12.5rem";

    switch( width ) {
      case "xs": return "6.25rem";
      case "s": return "11rem";
      case "m": return "15rem";
      case "l": return "18.75rem";
      case "xl": return "25rem";
      default: return "12.5rem";
    }
  }

  const widthPx = widthToPx(width);


  const internalOnChange = ( event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> ) => {
    const value = event.currentTarget.value
    const re = /^(\s*|\d+)$/;
    const valid = re.test(value);

    //if( valid )
    //    onChange( event );
    if( onChanged )
      onChanged( Number( value ) )
  }

  const formatedValue = (decimals != undefined && value != undefined) ? value.toFixed(decimals) : value;
  const valueToDisplay = readonly ? readonlyValue : formatedValue;
  

  return (
    <StyledWrapper style={{...style}}>
      <TextField
         // error={!!shouldShowError}
         // helperText={error}
          required = {!readonly}
          name={name}
          value={valueToDisplay}
          disabled={disabled}
          onChange={internalOnChange}
          //onBlur={onBlur}
          id={name}
          label={label}
          size="small"
          InputProps={{
            endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
            readOnly: readonly,
            style: {backgroundColor: !disabled ? Color.BACKGROUND_LIGHTEST : Color.BACKGROUND_DARK }
          }}
          inputProps={{
            style: {backgroundColor: !disabled ? Color.BACKGROUND_LIGHTEST : Color.BACKGROUND_DARK }
          }}
          style={{
            color: Color.TEXT_DARKER, 
            width: widthPx
          }}
        />
        { tooltip != undefined && (
            <InfoTooltip text={tooltip} style={{marginLeft: "0.375rem", marginTop: "0.5rem"}} />
                
        )}
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
    display: flex;
    align-items: start;
    flex-direction:row;

    margin: 1.25rem 0rem 0rem 0rem;
    width: 100%;
    flex-direction: row;
  }
`;
