import { useState, useMemo } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import IndexRoutes from "./routes";
import { GlobalModalProvider } from "./components/Shared/GlobalModal";
import UrlContext from "./contexts/Shared/UrlContext";
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next'
import i18next from "i18next";

export default function App() {
  const [url] = useState(window.location.origin)


  return (
    <UrlContext.Provider value={{ url }}>
        <Router>
          <GlobalModalProvider>
            <IndexRoutes/>
          </GlobalModalProvider>
        </Router>
      <ToastContainer />
    </UrlContext.Provider>
  );
}
