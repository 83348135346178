import { Coordinates } from 'src/models/Location';
import { create } from 'zustand';

const defaultLocation : Coordinates = {
    latitude: 45,
    longitude: 10,
}

export const useSelectedLocation = create<{
    location: Coordinates;
    altitude: number;
    setLocation: (stream: Coordinates) => void;
    setAltitude: (altitude: number) => void;
}>( (set) => ({
    location: defaultLocation,
    altitude: 0,
    setLocation: (location: Coordinates) => set( () => ({ location }) ),
    setAltitude: (altitude: number) => set( () => ({ altitude }) ),
}));

